import { c } from '@/utils/etc'

export interface LogoIconProps {
  className?: string
  fill?: string
}

const LogoIcon: React.FCC<LogoIconProps> = ({ className, fill }) => {
  return (
    <svg className={c('w-[100px]', className)} viewBox="0 0 1413.872 519.301">
      <defs>
        <clipPath id="clipPath135" clipPathUnits="userSpaceOnUse">
          <path d="M0 404h1086V0H0z"></path>
        </clipPath>
      </defs>
      <g transform="matrix(1.33333 0 0 -1.33333 -17.064 532.203)">
        <g>
          <g clipPath="url(#clipPath135)">
            <g transform="translate(395.57 204.936)">
              <path
                fill={fill || '#0081e7'}
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c0-61.857 50.323-112.176 112.18-112.176h88.495v35.951H112.18c-35.894 0-65.996 24.961-74.048 58.418h129.807v35.942H38.217c8.17 33.291 38.187 58.09 73.963 58.09h88.495v35.951H112.18C50.323 112.176 0 61.857 0 0"
              ></path>
            </g>
            <g transform="translate(507.749 313.571)">
              <path
                fill={fill || '#0081e7'}
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c-59.904 0-108.64-48.734-108.64-108.637 0-59.898 48.736-108.633 108.64-108.633h84.956v28.87H0c-36.963 0-68.829 25.137-77.49 61.129l-1.052 4.372H52.217v28.858H-78.478l1.076 4.384C-68.618-53.865-36.789-28.869 0-28.869h84.956V0zm92.037-224.351H0c-63.81 0-115.721 51.909-115.721 115.714C-115.721-44.83-63.81 7.081 0 7.081h92.037v-43.032H0c-31.995 0-59.875-20.757-69.304-51.009H59.299v-43.021H-69.413c9.326-30.446 37.252-51.337 69.413-51.337h92.037z"
              ></path>
            </g>
            <g transform="translate(715.471 128.712)">
              <path
                fill={fill || '#0081e7'}
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c-42.035 0-76.234 34.189-76.234 76.225 0 42.035 34.199 76.224 76.234 76.224s76.225-34.189 76.225-76.224C76.225 34.189 42.035 0 0 0m0 188.4c-61.858 0-112.176-50.318-112.176-112.175C-112.176 14.367-61.858-35.951 0-35.951c61.857 0 112.176 50.318 112.176 112.176C112.176 138.082 61.857 188.4 0 188.4"
              ></path>
            </g>
            <g transform="translate(715.473 277.62)">
              <path
                fill={fill || '#0081e7'}
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c-40.086 0-72.696-32.606-72.696-72.686 0-40.075 32.61-72.681 72.696-72.681 40.075 0 72.682 32.606 72.682 72.681C72.682-32.606 40.075 0 0 0m0-152.449c-43.99 0-79.778 35.781-79.778 79.763C-79.778-28.702-43.99 7.082 0 7.082c43.982 0 79.764-35.784 79.764-79.768 0-43.982-35.782-79.763-79.764-79.763m0 188.4c-59.902 0-108.636-48.734-108.636-108.637 0-59.898 48.734-108.632 108.636-108.632 59.898 0 108.632 48.734 108.632 108.632C108.632-12.783 59.898 35.951 0 35.951m0-224.35c-63.806 0-115.718 51.908-115.718 115.713C-115.718-8.879-63.806 43.032 0 43.032c63.806 0 115.714-51.911 115.714-115.718 0-63.805-51.908-115.713-115.714-115.713"
              ></path>
            </g>
            <g transform="translate(910.398 281.161)">
              <path
                fill={fill || '#0081e7'}
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0h159.265v35.951H0c-35.886 0-65.078-29.191-65.078-65.078 0-35.877 29.192-65.068 65.078-65.068h94.196c16.064 0 29.127-13.064 29.127-29.127 0-16.065-13.063-29.127-29.127-29.127H-65.078V-188.4H94.196c35.878 0 65.069 29.191 65.069 65.078 0 35.886-29.191 65.077-65.069 65.077H0c-16.064 0-29.127 13.063-29.127 29.127C-29.127-13.063-16.064 0 0 0"
              ></path>
            </g>
            <g transform="translate(848.863 96.302)">
              <path
                fill={fill || '#0081e7'}
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0h155.73c33.928 0 61.528 27.607 61.528 61.538 0 33.932-27.6 61.534-61.528 61.534H61.534c-18.012 0-32.665 14.655-32.665 32.669 0 18.009 14.653 32.659 32.665 32.659h155.724v28.87H61.534C27.603 217.27 0 189.662 0 155.731c0-33.927 27.603-61.527 61.534-61.527h94.196c18.016 0 32.669-14.654 32.669-32.666 0-18.015-14.653-32.669-32.669-32.669H0zm155.73-7.081H-7.082v43.032H155.73c14.108 0 25.588 11.48 25.588 25.587 0 14.108-11.48 25.584-25.588 25.584H61.534c-37.835 0-68.616 30.777-68.616 68.609 0 37.838 30.781 68.62 68.616 68.62h162.805v-43.033H61.534c-14.108 0-25.584-11.472-25.584-25.577 0-14.107 11.476-25.587 25.584-25.587h94.196c37.832 0 68.609-30.781 68.609-68.616 0-37.838-30.777-68.619-68.609-68.619"
              ></path>
            </g>
            <g transform="translate(414.18 45.84)">
              <path
                fill={fill || '#0081e7'}
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0h24.602l-.051-5.959H6.825v-9.627h16.147v-5.959H6.825v-14.108H0z"
              ></path>
            </g>
            <path
              fill={fill || '#0081e7'}
              fillOpacity="1"
              fillRule="nonzero"
              stroke="none"
              d="M445.146 45.84h6.825V10.187h-6.825z"
            ></path>
            <g transform="translate(486.861 45.84)">
              <path
                fill={fill || '#0081e7'}
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0h6.622v-35.653H.153l-18.336 24.549v-24.549h-6.672V0h6.468L0-24.601z"
              ></path>
            </g>
            <g transform="translate(511.769 23.582)">
              <path
                fill={fill || '#0081e7'}
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0h12.529L6.315 14.975zm18.132-13.396l-3.158 7.539H-2.445l-3.158-7.539h-7.08L2.954 22.258h7.079l15.434-35.654z"
              ></path>
            </g>
            <g transform="translate(567.692 45.84)">
              <path
                fill={fill || '#0081e7'}
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0h6.622v-35.653H.153l-18.336 24.549v-24.549h-6.672V0h6.468L0-24.601z"
              ></path>
            </g>
            <g transform="translate(605.714 38.964)">
              <path
                fill={fill || '#0081e7'}
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c-1.715.815-3.456 1.223-5.221 1.223-2.208 0-4.227-.528-6.061-1.579a11.467 11.467 0 01-4.329-4.355c-1.053-1.851-1.579-3.88-1.579-6.087 0-2.208.526-4.236 1.579-6.087a11.653 11.653 0 014.329-4.38c1.834-1.069 3.853-1.604 6.061-1.604 1.698 0 3.404.373 5.118 1.121a14.667 14.667 0 014.559 3.106l4.024-4.482a21.067 21.067 0 00-6.519-4.507c-2.48-1.104-4.992-1.656-7.538-1.656-3.431 0-6.57.815-9.423 2.445-2.853 1.63-5.094 3.846-6.724 6.647s-2.444 5.899-2.444 9.296c0 3.394.831 6.485 2.495 9.269 1.664 2.784 3.931 4.974 6.8 6.57 2.869 1.596 6.053 2.394 9.55 2.394 2.547 0 5.043-.509 7.487-1.527C4.609 4.788 6.697 3.378 8.43 1.579L4.456-3.412A13.429 13.429 0 010 0"
              ></path>
            </g>
            <path
              fill={fill || '#0081e7'}
              fillOpacity="1"
              fillRule="nonzero"
              stroke="none"
              d="M621.069 45.84h6.825V10.187h-6.825z"
            ></path>
            <g transform="translate(646.18 23.582)">
              <path
                fill={fill || '#0081e7'}
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0h12.529L6.315 14.975zm18.132-13.396l-3.158 7.539H-2.445l-3.158-7.539h-7.08L2.954 22.258h7.079l15.434-35.654z"
              ></path>
            </g>
            <g transform="translate(677.249 45.84)">
              <path
                fill={fill || '#0081e7'}
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0h7.793l11.816-22.971L31.324 0h7.793v-35.653h-6.264v25.568L21.749-31.986h-4.38L6.214-10.085v-25.568H0z"
              ></path>
            </g>
            <g transform="translate(726.4 45.84)">
              <path
                fill={fill || '#0081e7'}
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0h26.129v-5.959H6.825v-8.812h17.318v-5.958H6.825v-8.965H26.74v-5.959H0z"
              ></path>
            </g>
            <g transform="translate(786.194 45.84)">
              <path
                fill={fill || '#0081e7'}
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0h6.622v-35.653H.153l-18.336 24.549v-24.549h-6.672V0h6.468L0-24.601z"
              ></path>
            </g>
            <g transform="translate(798.877 45.84)">
              <path
                fill={fill || '#0081e7'}
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0h29.083v-6.061H17.979v-29.592h-6.875v29.592H0z"
              ></path>
            </g>
            <g transform="translate(842.273 38.582)">
              <path
                fill={fill || '#0081e7'}
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0a11.92 11.92 0 01-4.432-4.381c-1.087-1.85-1.63-3.895-1.63-6.137 0-2.241.552-4.295 1.656-6.163a12.31 12.31 0 014.431-4.431c1.851-1.087 3.863-1.63 6.036-1.63 2.139 0 4.126.543 5.959 1.63a11.976 11.976 0 014.355 4.431c1.069 1.868 1.604 3.922 1.604 6.163 0 2.242-.535 4.287-1.604 6.137A11.833 11.833 0 0112.02 0a11.626 11.626 0 01-5.959 1.604C3.888 1.604 1.867 1.069 0 0m15.61 5.322c2.904-1.596 5.187-3.786 6.851-6.57 1.664-2.784 2.496-5.875 2.496-9.27 0-3.396-.832-6.494-2.496-9.295-1.664-2.801-3.947-5.017-6.851-6.647-2.902-1.63-6.12-2.445-9.651-2.445-3.532 0-6.749.815-9.652 2.445-2.903 1.63-5.187 3.846-6.851 6.647-1.664 2.801-2.495 5.899-2.495 9.295 0 3.395.831 6.486 2.495 9.27 1.664 2.784 3.948 4.974 6.851 6.57s6.12 2.394 9.652 2.394c3.531 0 6.749-.798 9.651-2.394"
              ></path>
            </g>
            <g transform="translate(905.048 39.27)">
              <path
                fill={fill || '#0081e7'}
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c-1.919.611-3.608.917-5.068.917-1.63 0-2.921-.306-3.871-.917-.951-.611-1.426-1.46-1.426-2.547 0-.985.331-1.807.993-2.47.662-.662 1.494-1.206 2.496-1.629 1.001-.426 2.368-.892 4.1-1.402 2.411-.713 4.388-1.426 5.933-2.139 1.545-.712 2.861-1.766 3.947-3.158 1.087-1.392 1.63-3.226 1.63-5.501 0-2.173-.585-4.066-1.756-5.678-1.171-1.614-2.785-2.845-4.839-3.693-2.055-.849-4.406-1.273-7.054-1.273-2.785 0-5.501.516-8.149 1.553-2.649 1.036-4.958 2.419-6.927 4.151l2.851 5.807a20.847 20.847 0 016.062-3.922c2.241-.952 4.329-1.426 6.265-1.426 1.901 0 3.395.364 4.482 1.095 1.086.729 1.63 1.722 1.63 2.979 0 1.019-.331 1.859-.993 2.522-.662.662-1.503 1.196-2.522 1.604-1.018.407-2.411.865-4.177 1.375-2.411.713-4.379 1.408-5.908 2.088-1.528.679-2.835 1.707-3.922 3.082-1.087 1.375-1.63 3.183-1.63 5.424 0 2.071.552 3.879 1.656 5.425 1.103 1.545 2.665 2.741 4.686 3.59 2.02.849 4.355 1.274 7.003 1.274 2.309 0 4.592-.34 6.851-1.019 2.258-.679 4.269-1.596 6.035-2.751l-2.75-5.959C3.794-1.478 1.918-.611 0 0"
              ></path>
            </g>
            <g transform="translate(931.762 38.582)">
              <path
                fill={fill || '#0081e7'}
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0a11.92 11.92 0 01-4.432-4.381c-1.087-1.85-1.63-3.895-1.63-6.137 0-2.241.552-4.295 1.656-6.163a12.31 12.31 0 014.431-4.431c1.851-1.087 3.863-1.63 6.036-1.63 2.139 0 4.126.543 5.959 1.63a11.976 11.976 0 014.355 4.431c1.069 1.868 1.604 3.922 1.604 6.163 0 2.242-.535 4.287-1.604 6.137A11.833 11.833 0 0112.02 0a11.626 11.626 0 01-5.959 1.604C3.888 1.604 1.867 1.069 0 0m15.61 5.322c2.904-1.596 5.187-3.786 6.851-6.57 1.664-2.784 2.496-5.875 2.496-9.27 0-3.396-.832-6.494-2.496-9.295-1.664-2.801-3.947-5.017-6.851-6.647-2.902-1.63-6.12-2.445-9.651-2.445-3.532 0-6.749.815-9.652 2.445-2.903 1.63-5.187 3.846-6.851 6.647-1.664 2.801-2.495 5.899-2.495 9.295 0 3.395.831 6.486 2.495 9.27 1.664 2.784 3.948 4.974 6.851 6.57s6.12 2.394 9.652 2.394c3.531 0 6.749-.798 9.651-2.394"
              ></path>
            </g>
            <g transform="translate(964.002 45.84)">
              <path
                fill={fill || '#0081e7'}
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0h6.825v-29.49h15.382v-6.163H0z"
              ></path>
            </g>
            <g transform="translate(1001.335 23.582)">
              <path
                fill={fill || '#0081e7'}
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0h12.529L6.315 14.975zm18.132-13.396l-3.158 7.539H-2.445l-3.158-7.539h-7.08L2.954 22.258h7.079l15.434-35.654z"
              ></path>
            </g>
            <g transform="translate(1039.228 26.689)">
              <path
                fill={fill || '#0081e7'}
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0h8.048c2.58 0 4.55.561 5.908 1.682 1.357 1.12 2.037 2.783 2.037 4.991 0 2.173-.68 3.803-2.037 4.89-1.358 1.085-3.328 1.629-5.908 1.629H0zm15.484-16.502L9.321-5.959c-.272-.034-.696-.051-1.273-.051H0v-10.492h-6.825v35.653H8.048c4.617 0 8.182-1.069 10.696-3.209 2.512-2.138 3.769-5.161 3.769-9.065 0-2.785-.604-5.162-1.808-7.131-1.206-1.97-2.963-3.447-5.272-4.432l7.843-11.816z"
              ></path>
            </g>
            <path
              fill={fill || '#ffb800'}
              fillOpacity="1"
              fillRule="nonzero"
              stroke="none"
              d="M133.773 185.197H12.798v41.547h120.975z"
            ></path>
            <g transform="translate(170.879 11.446)">
              <path
                fill={fill || '#00b557'}
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0l-23.302 34.396 12.571 8.52A48.867 48.867 0 019.893 92.452l-37.692 199.877c-6.022 31.935 5.646 64.812 30.457 85.802l11.316 9.575 26.839-31.715-11.32-9.578c-13.412-11.347-19.722-29.118-16.462-46.382l37.692-199.88A90.406 90.406 0 0012.571 8.517z"
              ></path>
            </g>
            <g transform="translate(215.727 351.411)">
              <path
                fill={fill || '#0081e7'}
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0l-4.874-4.125c-9.615-8.135-14.139-20.871-11.806-33.239l.333-1.768c51.348-8.206 90.753-52.682 90.753-106.308 0-39.517-21.441-74.039-53.237-92.803 2.849-15.96 1.873-31.97-2.406-46.95 56.667 21.217 97.19 75.77 97.19 139.753C115.953-74.586 66.303-15.221 0 0"
              ></path>
            </g>
            <g transform="translate(80.312 240.593)">
              <path
                fill={fill || '#ffb800'}
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c8.794 25.842 27.101 47.323 50.776 60.176l-.083.44c-2.885 15.302-2.199 30.777 1.582 45.392C5.004 89.225-31.415 49.361-43.213 0z"
              ></path>
            </g>
            <g transform="translate(168.943 99.11)">
              <path
                fill={fill || '#ffb800'}
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c-41.383 5.167-75.469 33.875-88.556 72.239h-43.205c12.966-54.226 55.563-97.02 109.709-110.28l7.022 4.76C-4.085-25.871 1.6-12.995 0 0"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default LogoIcon
